<template>
<div class="page">
  <div class="top_menu">
    <div class="actived">
      <div>评估报告</div>
      <span></span>
    </div>
    <div @click="go_detail">
      <div>评估详情</div>
      <span></span>
    </div>
  </div>
  <div class="report" ref="codeRef">

    <div class="wrap">

      <h1>托育机构质量评估标准报告</h1>
      <p class="sub-title">Evaluation standards for quality of chlidcare institutions</p>
      <div class="inspect_info">
        <div class="inspect_info_item"><span class="lable">机构名称：</span><span class="text">{{inspect_info.organization_name}}</span></div>
        <div v-if="inspect_info.type == 1">
          <div class="inspect_info_item"><span class="lable">报告编号：</span><span class="text">{{inspect_info.serial}}</span></div>
          <div class="inspect_info_item"><span class="lable">提交时间：</span><span class="text">{{inspect_info.submit_date}}</span></div>
          <div class="inspect_info_item"><span class="lable">自评用时：</span><span class="text">{{inspect_info.total_time}}</span></div>
          <div class="inspect_info_item"><span class="lable">评审时间：</span><span class="text">{{inspect_info.examine_date}}</span></div>
        </div>
        <div v-if="inspect_info.type !=1">
          <div class="inspect_info_item"><span class="lable">报告编号：</span><span class="text">{{inspect_info.serial}}</span></div>
          <div class="inspect_info_item"><span class="lable">提交时间：</span><span class="text">{{inspect_info.examine_date}}</span></div>
        </div>
      </div>
      <div class="model_title">
        <span class="model_title_left"></span>
        <span class="model_title_center">评估等级：</span>
        <span v-if="inspect_info.type != 1">
          <span v-if="inspect_info.results == 1" style="color:#F04134">D(需要重大改进)</span>
          <span v-else-if="inspect_info.results == 2" style="color:#FFBA01">C(不合格)</span>
          <span v-else-if="inspect_info.results == 3" style="color:#1ABE6B">B(合格)</span>
          <span v-else-if="inspect_info.results == 4" style="color:#0079FE">A(优秀)</span>
        </span>
        <span v-if="inspect_info.type == 1">
          <span v-if="inspect_info.examine_results == 0" style="color:#F04134">未评审</span>
          <span v-if="inspect_info.examine_results == 1" style="color:#F04134">D(需要重大改进)</span>
          <span v-else-if="inspect_info.examine_results == 2" style="color:#FFBA01">C(不合格)</span>
          <span v-else-if="inspect_info.examine_results == 3" style="color:#1ABE6B">B(合格)</span>
          <span v-else-if="inspect_info.examine_results == 4" style="color:#0079FE">A(优秀)</span>
        </span>
      </div>
      <div class="model_content">
        <div v-if="inspect_info.type == 1">
          <span>评审总分：</span>
          <span>{{inspect_info.examine_score * 1}}分</span>
        </div>
        <div v-if="inspect_info.type != 1">
          <span>评审总分：</span>
          <span>{{inspect_info.score * 1}}分</span>
        </div>
        <div v-if="inspect_info.type == 1">
          标<img class="icon_start" style="transform:translateY(-1px)" src="~@/assets/index/star.png" alt="">通过项：{{inspect_info.examine_stars}}项
        </div>
        <div v-if="inspect_info.type != 1">
          标<img class="icon_start" style="transform:translateY(-1px)" src="~@/assets/index/star.png" alt="">通过项：{{inspect_info.stars}}项
        </div>
      </div>
      <div class="model_title">
        <span class="model_title_left"></span>
        <span class="model_title_center">改进建议</span>
      </div>
      <div class="model_content">
        <div v-show="opinion.length" v-for="(item,index) of opinion" :key="index">
          {{item.content}}
        </div>
        <div v-show="!opinion.length">无</div>
      </div>

      <div class="part">
        <div class="model_title">
          <span class="model_title_left"></span>
          <span class="model_title_center">7项指标得分对比情况</span>
        </div>
        <div class="model_content chart_tit">
          <!-- <div class="chart_tit_icon"></div>

<div>评审得分</div> -->
        </div>
        <div ref="getwidth" id="chart"></div>
        <el-table :data="tableData" style="width: 100%;font-size: 0.7rem">
          <el-table-column align="center" :width="inspect_info.type != 1?table_width/4:parseInt(table_width-parseInt(table_width/6)*5)" prop="name" label="指标">
          </el-table-column>
          <el-table-column align="center" :width="inspect_info.type != 1?table_width/4:table_width/6" label="分值">
            <template slot-scope="scope">
              {{scope.row.score * 1}}分
            </template>
          </el-table-column>
          <el-table-column align="center" :width="inspect_info.type != 1?table_width/2:table_width/3" label="自评" v-if="inspect_info.type == 1">
            <el-table-column align="center" :width="inspect_info.type != 1?table_width/4:table_width/6" label="得分">
              <template slot-scope="scope">
                {{scope.row.inspect_score * 1}}分
              </template>
            </el-table-column>
            <el-table-column align="center" :width="inspect_info.type != 1?table_width/4:table_width/6" label="比重">
              <template slot-scope="scope">
                {{(scope.row.inspect_ratio * 100).toFixed()}}%
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" :width="inspect_info.type != 1?table_width/2:table_width/3" label="评审" v-if="inspect_info.type != 1">
            <el-table-column align="center" :width="inspect_info.type != 1?table_width/4:table_width/6" label="得分">
              <template slot-scope="scope">
                <template v-if="scope.row.name=='总分'">
                  <p v-if="inspect_info.results == 1" style="color:#F04134;text-align: center">{{scope.row.inspect_score * 1}}分</p>
                  <p v-if="inspect_info.results == 2" style="color:#FFBA01;text-align: center">{{scope.row.inspect_score * 1}}分</p>
                  <p v-if="inspect_info.results == 3" style="color:#1ABE6B;text-align: center">{{scope.row.inspect_score * 1}}分</p>
                  <p v-if="inspect_info.results == 4" style="color:#0079FE;text-align: center">{{scope.row.inspect_score * 1}}分</p>
                </template>
                <template>
                  <p v-if="scope.row.examine_results == 1" style="text-align: center;">{{scope.row.inspect_score * 1}}分</p>
                  <p v-if="scope.row.examine_results == 2" style="color:#F04134;text-align: center;">{{scope.row.inspect_score * 1}}分</p>
                </template>
                <!-- {{scope.row.inspect_score * 1}}分 -->
              </template>
            </el-table-column>
            <el-table-column align="center" :width="inspect_info.type != 1?table_width/4:table_width/6" label="比重">
              <template slot-scope="scope">
                <template v-if="scope.row.name == '总分'">
                  <p v-if="inspect_info.results == 1" style="color:#F04134;text-align: center">{{(scope.row.inspect_ratio * 100).toFixed()}}%</p>
                  <p v-if="inspect_info.results == 2" style="color:#FFBA01;text-align: center">{{(scope.row.inspect_ratio * 100).toFixed()}}%</p>
                  <p v-if="inspect_info.results == 3" style="color:#1ABE6B;text-align: center">{{(scope.row.inspect_ratio * 100).toFixed()}}%</p>
                  <p v-if="inspect_info.results == 4" style="color:#0079FE;text-align: center">{{(scope.row.inspect_ratio * 100).toFixed()}}%</p>
                </template>
                <template v-else>
                  <p v-if="scope.row.examine_results == 1" style="text-align: center;">{{(scope.row.inspect_ratio * 100).toFixed()}}%</p>
                  <p v-if="scope.row.examine_results == 2" style="color:#F04134;text-align: center;">{{(scope.row.inspect_ratio * 100).toFixed()}}%</p>
                </template>
                <!-- {{(scope.row.inspect_ratio * 100).toFixed()}}% -->
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" :width="inspect_info.type != 1?table_width/2:table_width/3" label="评审" v-if="inspect_info.type == 1">
            <el-table-column align="center" :width="inspect_info.type != 1?table_width/4:table_width/6" label="得分">
              <template slot-scope="scope">
                <!-- <p v-if="scope.row.examine_results == 0" style="color:#000;text-align: center;">{{(scope.row.examine_score * 1).toFixed()}}分</p>
                <p v-if="scope.row.examine_results == 1" style="color:#F04134;text-align: center;">{{scope.row.examine_score * 1}}分</p>
                <p v-else-if="scope.row.examine_results == 2" style="color:#FFBA01;text-align: center;">{{scope.row.examine_score * 1}}分</p>
                <p v-else-if="scope.row.examine_results == 3" style="color:#1ABE6B;text-align: center;">{{scope.row.examine_score * 1}}分</p>
                <p v-else-if="scope.row.examine_results == 4" style="color:#0079FE;text-align: center;">{{scope.row.examine_score * 1}}分</p>
                <p v-else style="color:#0079FE"></p> -->
                <template v-if="scope.row.name=='总分'">
                  <p v-if="inspect_info.examine_results == 1" style="color:#F04134;text-align: center">{{scope.row.examine_score * 1}}分</p>
                  <p v-if="inspect_info.examine_results == 2" style="color:#FFBA01;text-align: center">{{scope.row.examine_score * 1}}分</p>
                  <p v-if="inspect_info.examine_results == 3" style="color:#1ABE6B;text-align: center">{{scope.row.examine_score * 1}}分</p>
                  <p v-if="inspect_info.examine_results == 4" style="color:#0079FE;text-align: center">{{scope.row.examine_score * 1}}分</p>
                </template>
                <template>
                  <p v-if="scope.row.examine_results == 1" style="text-align: center;">{{scope.row.examine_score * 1}}分</p>
                  <p v-if="scope.row.examine_results == 2" style="color:#F04134;text-align: center;">{{scope.row.examine_score * 1}}分</p>
                </template>
                
              </template>
            </el-table-column>
            <el-table-column align="center" :width="inspect_info.type != 1?table_width/4:table_width/6" label="比重">
              <template slot-scope="scope">
                <template v-if="scope.row.name == '总分'">
                  <p v-if="inspect_info.examine_results == 1" style="color:#F04134;text-align: center">{{(scope.row.examine_ratio * 1000000 / 10000).toFixed()}}%</p>
                  <p v-if="inspect_info.examine_results == 2" style="color:#FFBA01;text-align: center">{{(scope.row.examine_ratio * 1000000 / 10000).toFixed()}}%</p>
                  <p v-if="inspect_info.examine_results == 3" style="color:#1ABE6B;text-align: center">{{(scope.row.examine_ratio * 1000000 / 10000).toFixed()}}%</p>
                  <p v-if="inspect_info.examine_results == 4" style="color:#0079FE;text-align: center">{{(scope.row.examine_ratio * 1000000 / 10000).toFixed()}}%</p>
                </template>
                <template v-else>
                  <p v-if="scope.row.examine_results == 1" style="text-align: center;">{{(scope.row.examine_ratio * 100).toFixed()}}%</p>
                  <p v-if="scope.row.examine_results == 2" style="color:#F04134;text-align: center;">{{(scope.row.examine_ratio * 100).toFixed()}}%</p>
                </template>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="color">
          <div>
            <div class="span" style="background:#F04134"></div>D级（需要重大改进）
          </div>
          <div>
            <div class="span" style="background:#FFBA01"></div>C级（不合格）
          </div>
        </div>
        <div class="color color2">
          <div>
            <div class="span" style="background:#1ABE6B"></div>B级（合格）
          </div>
          <div>
            <div class="span" style="background:#0079FE"></div>A级（优秀）
          </div>
        </div>
      </div>
      <div class="part">
        <div class="model_title">
          <span class="model_title_left"></span>
          <span class="model_title_center">标<img class="icon_start" style="transform:translateY(-1px)" src="~@/assets/index/star.png" alt="">指标评审情况</span>
        </div>

        <div v-if="stars_num.inspect" class="progress">
          <img src="~@/assets/charts/ziping.png" alt="">
          <div class="right">
            <div class="line">
              <span>通过</span>
              <el-progress class="pro" :show-text="false" :percentage="stars_num.inspect.percentage0" color="#409eff"></el-progress>
              <span>{{stars_num.inspect.is_stars}}项</span>
            </div>
            <div class="line">
              <span>未通过</span>
              <el-progress class="pro" :show-text="false" :percentage="stars_num.inspect.percentage1" color="#f56c6c"></el-progress>
              <span>{{stars_num.inspect.no_stars}}项</span>
            </div>
          </div>
        </div>
        <div v-if="stars_num.examine" class="progress">
          <img src="~@/assets/charts/pingshen.png" alt="">
          <div class="right">
            <div class="line">
              <span>通过</span>
              <el-progress class="pro" :show-text="false" :percentage="stars_num.examine.percentage0" color="#409eff"></el-progress>
              <span>{{stars_num.examine.is_stars}}项</span>
            </div>
            <div class="line">
              <span>未通过</span>
              <el-progress class="pro" :show-text="false" :percentage="stars_num.examine.percentage1" color="#f56c6c"></el-progress>
              <span>{{stars_num.examine.no_stars}}项</span>
            </div>
          </div>
        </div>
      </div>
      <div class="part" style="margin-bottom: 100px;">
        <div class="model_title">
          <span class="model_title_left"></span>
          <span class="model_title_center">标<img class="icon_start" style="transform:translateY(-1px)" src="~@/assets/index/star.png" alt="">指标没通过明细</span>
        </div>
        <div v-if="stars_list.length<1" class="content">无</div>
        <template v-for="(item,index) of stars_list">
          <div :key="index" class="item">
            <div class="name">
              <div class="object_pname_icon"></div>
              <div>{{item.object_pname}}</div>
            </div>
            <p class="content" v-for="(item2,index2) of item.object_name" :key="index2">{{item2}}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
  <!-- <el-tabs class="tabs">
    <el-tab-pane label="评估报告">
    </el-tab-pane>
    <el-tab-pane label="评估详情">
    </el-tab-pane>
  </el-tabs> -->
  <img id="newimg" :src="imgSrc" style="width:100%;opacity:1;position:absolute;left:0;top:0;" />
  <!-- <el-footer id="footer">
    <span>ICP备案编号：京ICP备13043694号</span>
    <span><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049964" target="_blank" style="text-decoration: none;color: #fff;cursor: pointer;"><img src="~@/assets/index/beian.png" style="float:left;margin-right:4px;"/>京公网安备 11010502049964号</a></span>
    <span>技术支持：北京京学科技发展集团有限公司</span>
  </el-footer> -->
</div>
</template>

<script>
import html2canvas from "html2canvas"
export default {
  data() {
    return {
      inspect_info: '',
      stars_list: [],
      opinion: '',
      stars_num: '',
      inspect_id: '',
      loading: false,
      imgSrc: '',
      chart: '',
      table_width: 0,
      tableData: []
    }
  },
  mounted() {
    this.table_width = this.$refs.getwidth.clientWidth;
    console.log(this.table_width, 'table_width');
    this.inspect_id = this.$route.query.inspect_id;
    console.log(this.inspect_id);
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/report/info",
        data: {
          inspect_id: this.inspect_id
        }
      });
      this.loading = false;
      if (res.code == 0) {
        console.log(res.data);
        this.inspect_info = res.data.inspect_info;
        this.opinion = res.data.opinion;
        this.stars_list = res.data.stars_list;
        this.tableData = res.data.object_list;
        this.tableData.push({
          examine_ratio: (res.data.score_all.examine_score_all / res.data.score_all.score_all).toFixed(2),
          examine_score: res.data.score_all.examine_score_all,
          inspect_ratio: (res.data.score_all.inspect_score_all / res.data.score_all.score_all).toFixed(2),
          inspect_score: res.data.score_all.inspect_score_all,
          examine_results: 0,
          name: "总分",
          score: res.data.score_all.score_all,
        })
        /*
        for(var i=0;i<this.tableData.length;i++){
        	if(this.tableData[i].inspect_ratio<0.5){

this.tableData[i].inspect_color='red';
        	}
        	if(this.tableData[i].inspect_ratio>=0.5&&this.tableData[i].inspect_ratio<0.6){

this.tableData[i].inspect_color='orange';
        	}
        	if(this.tableData[i].inspect_ratio>=0.6&&this.tableData[i].inspect_ratio<0.8){

this.tableData[i].inspect_color='olive';
        	}
        	if(this.tableData[i].inspect_ratio>=0.8){

this.tableData[i].inspect_color='blue';
        	}
        	if(this.tableData[i].examine_ratio<0.5){

this.tableData[i].examine_color='red';
        	}
        	if(this.tableData[i].examine_ratio>=0.5&&this.tableData[i].examine_ratio<0.6){

this.tableData[i].examine_color='orange';
        	}
        	if(this.tableData[i].examine_ratio>=0.6&&this.tableData[i].examine_ratio<0.8){

this.tableData[i].examine_color='olive';
        	}
        	if(this.tableData[i].inspect_ratio>=0.8){

this.tableData[i].examine_color='blue';
        	}

        }
        */
        console.log(this.tableData, 'tableData');

        let a = res.data.stars_num;
        console.log(a, 'a');
        var stars_num = {}
        if (a.is_stars) {
          stars_num['examine'] = a;
        } else {
          stars_num = a;
        }
        for (var k in stars_num) {
          if (stars_num[k].is_stars + stars_num[k].no_stars) {
            stars_num[k].percentage0 = (stars_num[k].is_stars / (stars_num[k].is_stars + stars_num[k].no_stars) * 100).toFixed(0) * 1;
            stars_num[k].percentage1 = (stars_num[k].no_stars / (stars_num[k].is_stars + stars_num[k].no_stars) * 100).toFixed(0) * 1;
          } else {
            stars_num[k].percentage0 = 0;
            stars_num[k].percentage1 = 0;
          }
        }
        this.stars_num = stars_num;

        this.chartInit();
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    codeCanvas() {
      //整个图片转换完并赋值给图片容器
      html2canvas(this.$refs.codeRef).then(canvas => {
        const base64 = canvas.toDataURL('image/png')
        this.imgSrc = base64
      })
    },
    imgDownload() {
      // 下载
      const link = document.createElement('a');
      link.href = this.imgSrc;
      link.setAttribute('download', '报告')
      link.click();
    },
    go_detail: function () {
      this.$router.push({
        path: '/list',
        query: {
          id: this.inspect_id,
          type: 3,
          is_new: 0
        }
      })
    },
    chartInit() {
      let data = JSON.parse(JSON.stringify(this.tableData));
      data.pop();
      let indicator = data.map(e => {
        return {
          text: e.name,
          max: e.score * 1
        }
      });
      let value1 = data.map(e => {
        return e.inspect_score * 1
      });
      let value2 = data.map(e => {
        return e.examine_score * 1
      });
      let dataArr = [];
      if (this.inspect_info.type == 1) {
        dataArr = [{
            value: value1,
            name: '自评得分',
            symbol: 'rect',
            symbolSize: 12,
            lineStyle: {
              type: 'dashed'
            },
            label: {
              show: true,
              formatter: function (params) {
                return params.value;
              }
            }
          },
          {
            value: value2,
            name: '评审得分',
            symbolSize: 12,
            label: {
              show: false,
              formatter: function (params) {
                return params.value;
              }
            },
            areaStyle: {
              color: new this.$echarts.graphic.RadialGradient(0.1, 0.6, 1, [{
                  color: 'rgba(255, 145, 124, 0.1)',
                  offset: 0
                },
                {
                  color: 'rgba(255, 145, 124, 0.9)',
                  offset: 1
                }
              ])
            }
          }
        ]
      } else {
        dataArr = [{
          value: value1,
          name: '评审得分',
          symbol: 'rect',
          symbolSize: 12,
          lineStyle: {
            type: 'dashed'
          },
          label: {
            show: true,
            formatter: function (params) {
              return params.value;
            }
          }
        }]
      }
      this.chart = this.$echarts.init(document.getElementById('chart'));
      let option = {
        color: ['#0083F5', '#F89400', '#56A3F1', '#FF917C'],
        legend: {
          position: 'top'
        },
        radar: [{},
          {
            // shape: 'circle',
            name: {
              textStyle: {
                color: '#000',

                borderRadius: 3,
                padding: [12, 5]
              },
              formatter: function (text) {
                if (text == '办托条件') {
                  //return text + '\n'
                }
                if (text == '安全保障' || text == '保育照护') {
                  var strlength = text.length;
                  if (strlength % 2 == 1) {
                    text = text.replace(/\S{2}/g, function (match) {
                      console.log(match);
                      return match + '\n'
                    })
                  } else {
                    text = text.replace(/\S{2}/g, function (match) {
                      console.log(match);
                      return match + '\n'
                    })
                    strlength = text.length;
                    text = text.substring(0, strlength - 1);
                  }
                  return text
                }
                return text;
              },
            },
            indicator: indicator
          },
        ],
        series: [{
          type: 'radar',
          radarIndex: 1,
          data: dataArr
        }]
      };
      option && this.chart.setOption(option);
      this.chart.on('legendselectchanged', (params)=>{
        if(dataArr.length < 2) return
        if(!params.selected['自评得分'] && params.selected['评审得分']){
          dataArr[1].label.show = true
        }else{
          dataArr[1].label.show = false
        }
        option.series[0].data = dataArr
        this.chart.setOption(option)               
      }) 
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;

  color: #000;

  .report {
    padding: 10px 20px;
  }

  img {
    width: 1200px;
  }

  .inspect_info {
    text-align: left;
    width: 100%;
    padding: 1rem 0;
  }

  // .inspect_info_item{ height: 1.5rem;}
  .inspect_info .lable {
    font-size: 0.9rem;
  }

  .inspect_info .text {
    font-size: 0.85rem;
  }

  .model_title {
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    padding: 0.3rem 0;
  }

  .model_title .model_title_left {
    height: 0.9rem;
    width: 0.3rem;
    border-radius: 0.15rem;
    background: #0083F5;
    margin-right: 0.5rem;
  }

  .model_title .model_title_center {
    font-size: 1rem;
  }

  .model_title span {
    font-size: 1rem;
    font-weight: 600;
  }

  .model_content {
    width: 100%;
    padding-left: 1rem;
    text-align: left;
    padding: 0.5rem 0;
  }

  .model_content div {
    padding: 0.2rem 0;
    color: #666666;
  }

  .icon_start {
    width: 0.8rem;
    height: 0.8rem;
  }

  .chart_tit {
    display: flex;
    align-items: center;
  }

  .chart_tit_icon {
    width: 0.4rem;
    height: 0.4rem;
    padding: 0 !important;
    border-radius: 0.2rem;
    background: #F89400;
    margin-right: 0.5rem;
  }

  .top_menu {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #ccc;
    align-items: center;
    justify-content: space-around;
  }

  .top_menu>div {
    height: 3rem;
    position: relative;
    line-height: 3rem;
  }

  .top_menu>div span {
    position: absolute;
    left: 50%;
    margin-left: -1.5rem;
    bottom: 0rem;
    width: 3rem;
    background: #0083F5;
    height: 0.2rem;
    display: none;
  }

  .top_menu .actived span {
    display: block;
  }

  .top_menu .actived {
    color: #0083F5
  }

  .el-table__header {
    width: 100% !important;
  }

  .xing-title {
    display: flex;
    align-items: center;

    img {
      width: 18px;
      margin: 0 2px;
    }
  }

  .report {
    background: #fff;
    overflow: auto;
    color: #000;

    .wrap {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    h1 {
      font-size: 1.3rem;
      font-weight: 500;
      color: #000000;
      font-weight: 600;
      line-height: 2rem;
      margin: 0
    }

    .sub-title {
      font-size: 0.65rem;
      font-weight: 400;
      color: #666666;
      line-height: 1rem;
      margin: 0
    }

    .part {
      width: 100%;

      .title {
        width: 100%;
        text-align: left;
        position: relative;
        height: 24px;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;

        &::before {
          content: '';
          width: 5px;
          height: 24px;
          background: #0083F5;
          position: absolute;
          left: -15px;
          top: 0;
        }
      }

      .name {
        text-align: left;
        margin-top: 1rem;
        font-size: 0.9rem;
      }

      .content {
        text-align: left;
        color: #666666;
      }

      .advice {
        font-size: 16px;
        color: #000;
        margin-bottom: 10px;
      }

      #chart {
        width: 100%;
        height: 400px;
        margin: 0 auto;
      }
    }

    .color {
      display: flex;
      font-size: 0.8rem;
      justify-content: space-around;
      margin-top: 0.5rem;
      color: #000;

      >div {
        display: flex;
        align-items: center;

        .span {
          display: block;
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 0.3rem;
          margin-right: 0.2rem;
        }
      }
    }

    .color2 {
      margin-bottom: 0.5rem;
    }

    .progress {
      display: flex;
      margin-bottom: 0.2rem;
      align-items: center;

      img {
        width: 3rem;
        margin-right: 0.5rem;

      }

      .right {
        height: 66px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 0.8rem;
        flex: auto;

        .line {
          display: flex;
          align-items: center;
          height: 26px;

          span {
            flex: 0 0 60px;
            text-align: left;
          }

          span:last-of-type {
            padding-left: 20px;
          }

          .pro {
            flex: auto;
          }
        }
      }
    }

    .item {
      .name {
        font-weight: bold;
        display: flex;
        align-items: center;

        .object_pname_icon {
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 0.2rem;
          background: #0083F5;
          margin-right: 0.5rem;
        }
      }

      p {
        font-size: 0.8rem;
        margin-bottom: 0.3rem;
        line-height: 1.2rem;
      }
    }
  }
}
</style>

<style lang="scss">
.page {
  .el-tabs__nav-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-tabs__item {
    padding: 14px 40px 0;
    font-size: 20px;
    height: 64px;
    line-height: 50px;
    color: #666666;
  }

  .el-tabs__item.is-active {
    color: #333;
    font-weight: bold;
  }

  .el-descriptions-item__container {
    font-size: 16px;
    color: #000;

    .el-descriptions-item__label {
      font-weight: bold;
    }
  }

  .des-two .el-descriptions-row {
    .el-descriptions-item:last-of-type {
      .el-descriptions-item__container {
        justify-content: flex-end;
      }
    }
  }

  .el-descriptions-item__container .el-descriptions-item__content {
    flex: 0 0 auto;
  }

  .el-table--small {
    font-size: 0.8rem;
    padding: 0;
  }
}
</style>
